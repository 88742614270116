import { LocationQueryValue } from "vue-router";
import { ApiResponse, WSClient } from "./WSClient";
import { TempNode } from "@/types/utils";
import { Variable, Cat } from "@/types/serie";
import { Store } from "vuex";
import { State } from "@/store";
import identity_consts from "@/ems/identity_vars";
import DOMPurify from "dompurify";

export async function getServerEnvParams(keys: String[], client?: WSClient): Promise<Record<string, string>> {
  return (client ?? new WSClient()).queryWs("GET", "env_param", { key: keys.join("|") });
}

export async function get_version(client?: WSClient): Promise<string> {
  interface VResp extends ApiResponse {
    version: any;
  }
  let res = await (client ?? new WSClient()).queryWs<VResp>("GET", "env_version");
  return res.version?.Version;
}

export async function isDemoSerie(serie_id: string, client?: WSClient): Promise<boolean> {
  if (serie_id === "demo") return true;
  const res = await getServerEnvParams(["DEMO_SERIES_ID"], client);
  return parseInt(res.demo_series_id) === parseInt(serie_id);
}

export function elipsis(input: string | number | null | undefined, max: number): string {
  if (input == null || input == undefined) return "";
  if (typeof input == "number") input = input.toString();
  if (input.length < max) return input;
  return input.substring(0, max - 3) + "...";
}

export function wupcase(value: string | undefined | null): string {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function openHelp(articleId: string) {
  (window as any)?.FreshworksWidget("open", "article", { id: articleId });
}

export function getFirstParam(param: string | string[] | LocationQueryValue | LocationQueryValue[]): string | null {
  if (!param) return null;
  return Array.isArray(param) ? param[0] : param;
}

export function parseIntId(intStr: number | string | undefined | null, default_num: number): number {
  if (intStr === undefined || intStr === null || intStr === "") return default_num;
  if (typeof intStr === "number") return intStr;
  return parseInt(intStr);
}

export function capitalize(str: string): string {
  return str && str.charAt(0).toUpperCase() + str.slice(1);
}

export function plurals(str: string, nb: number | undefined | null): string {
  if (nb === undefined || nb === null || nb < 2) return str;
  return str + "s";
}

export function joinAnd(arr: string[]): string {
  let resp = arr.join(",");
  if (arr.length > 1) {
    resp = arr.slice(0, -1).join(", ");
    resp = resp + " and " + arr.slice(-1);
  }
  return resp;
}

export function getIndexByCriteria(
  sourceArr: Record<string | number, any>[],
  propName: string | number,
  criteria: any
) {
  for (let index = 0; index < sourceArr.length; index++) {
    const element = sourceArr[index];
    if (element[propName] == criteria) return index;
  }
  return -1;
}

export function buildParentChildObjects(data: TempNode[]): {
  parents: { [key: number]: number[] };
  children: { [key: number]: number[] };
} {
  const parentObject: { [key: number]: number[] } = {};
  const childrenObject: { [key: number]: number[] } = {};

  function traverseNodes(node: TempNode, parentIDs: number[] = []) {
    if (!node || !node.CatId) {
      return;
    }

    const currentID = node.CatId;
    const combinedIDs = [...parentIDs, currentID];

    parentObject[currentID] = parentIDs;

    if (node.children && node.children.length > 0) {
      childrenObject[currentID] = node.children.map((child) => child.CatId);
      parentIDs.forEach(
        (parentId: number) => (childrenObject[parentId] = childrenObject[parentId].concat(childrenObject[currentID]))
      );
      node.children.forEach((child) => {
        traverseNodes(child, combinedIDs);
      });
    }
  }

  data.forEach((item) => {
    traverseNodes(item);
  });
  // Add default identity vars
  parentObject[-1] = [];
  return {
    parents: parentObject,
    children: childrenObject,
  };
}
export function formatDate(dateString: string | number | undefined) {
  if (dateString === undefined) {
    return "";
  }
  let dateValue = new Date();
  if (isNaN(Number(dateString))) {
    dateString = (dateString as string).replace(/-/g, "/");
    dateValue = new Date(dateString + " GMT");
  } else {
    dateValue = new Date(Number(dateString));
  }

  const dateOptions: object = {
    dateStyle: "long",
    timeZone: "GMT",
  };
  const timeoptions: object = {
    timeZone: "GMT",
    timeZoneName: "short",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  return `${dateValue.toLocaleDateString("en-US", dateOptions)} ${dateValue.toLocaleTimeString("en-Uk", timeoptions)}`;
}

export function check_extra_required_fields(store: Store<State>, create: boolean = true) {
  var required_field_vars: Variable[] = [];
  const identity_vars = identity_consts.patient_input_form_vars;
  const serie_extra = store.state.serie?.Extra?.identity_variables;
  if (serie_extra) {
    if (serie_extra?.collect_first_name && create) {
      required_field_vars.push(identity_vars.FirstName as Variable);
    }
    if (serie_extra?.collect_last_name && create) {
      required_field_vars.push(identity_vars.LastName as Variable);
    }
    if (!create && (serie_extra?.collect_first_name || serie_extra?.collect_last_name)) {
      required_field_vars.push(identity_vars.Initials as Variable);
    }
    if (serie_extra?.collect_birthdate) {
      required_field_vars.push(identity_vars.BirthDate as Variable);
    }
    if (serie_extra?.collect_gender) {
      required_field_vars.push(identity_vars.Gender as Variable);
    }
  }
  required_field_vars.push(identity_vars.InclusionDate as Variable);
  if (serie_extra) {
    if (serie_extra?.collect_inclusion_last_follow_up) {
      required_field_vars.push(identity_vars.LastConsultation as Variable);
    }
    if (serie_extra?.collect_death_date) {
      required_field_vars.push(identity_vars.DeathDate as Variable);
    }
    if (serie_extra?.collect_email) {
      required_field_vars.push(identity_vars.Email as Variable);
    }
  }
  return required_field_vars;
}

export function sanitize_value(value: string) {
  return convertHtmlEntityToAngleBrackets(DOMPurify.sanitize(value, { ALLOWED_TAGS: [] }));
}
export const convertHtmlEntityToAngleBrackets = (fieldText: string) => {
  const htmlEntities = /&(lt|gt|amp);/;
  const isHtmlEntityUsed = htmlEntities.test(fieldText);
  if (isHtmlEntityUsed) {
    return fieldText.replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&amp;/g, "&");
  } else {
    return fieldText;
  }
};

export const get_utf8_size = function (input: string) {
  let size = 0;
  for (let i = 0; i < input.length; i++) {
    const codePoint = input.codePointAt(i);
    if (codePoint) {
      if (codePoint <= 0x7f) {
        size += 1;
      } else if (codePoint <= 0x7ff) {
        size += 2;
      } else if (codePoint <= 0xffff) {
        size += 3;
      } else if (codePoint <= 0x10ffff) {
        size += 4;
      }
    }
  }
  return size;
};

/**
 * Printable percentage.
 */
export function percent(
  numerator: number | null | undefined,
  denominator: number | null | undefined,
  decimals: number
): string | undefined {
  if (
    numerator === undefined ||
    denominator === undefined ||
    numerator === null ||
    denominator === null ||
    denominator === 0
  ) {
    return undefined;
  }
  const val = (numerator * 100) / denominator;
  return val.toFixed(decimals) + " %";
}

const count_format = new Intl.NumberFormat("en-US");
export function format_count(num: number | null | undefined): string {
  if (num === null || num === undefined) return "";
  return count_format.format(num);
}

type FormatFunction = (a: number | null | undefined) => string;
export function get_number_formater(digits: number): FormatFunction {
  const number_format = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return (num: number | null | undefined): string => {
    if (num === null || num === undefined) return "";
    return number_format.format(num);
  };
}

export function parseIfExists(key: string): any | null {
  let str = sessionStorage.getItem(key);
  if (!str || str == "{}" || str == "[]") return null;
  try {
    return str && JSON.parse(str);
  } catch (err) {
    sessionStorage.removeItem(key);
    console.log("Error parsin from local storage " + key + " : " + str);
  }
}

export function nl2p(input: string): string {
  return input
    .split("\n")
    .map((line) => "<p>" + line + "</p>")
    .join("");
}

export function stripHtmlTags(html: string): string {
  return html.replace(/<[^>]*>/gm, "");
}

export function CatsToFlatList(cats: Cat[]) {
  return cats.map((c: Cat) => flatten_cat(c)).reduce((a: Cat[], c: Cat[]) => a.concat(c), []);
}
function flatten_cat(cat: Cat): Cat[] {
  if (!cat.children) {
    return [cat];
  }
  let child_cats = cat.children?.map((c) => flatten_cat(c)).reduce((a, c) => a.concat(c), []);
  return [cat].concat(child_cats);
}

export function pruneSelectedCats(cats: Cat[] | undefined, selected_ids: (number | string)[]) {
  if (!cats) {
    return [];
  }
  let new_cats = JSON.parse(JSON.stringify(cats)) as Cat[];
  new_cats = new_cats.filter((x) => selected_ids.includes(x.CatId));
  new_cats = new_cats.map((c) => {
    c.children = pruneSelectedCats(c.children ?? [], selected_ids);
    return c;
  });
  return new_cats;
}
