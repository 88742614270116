const identity_vars = [
  {
    VarId: "Initials",
    Name: "Initials",
    TypeId: 5,
    general: true,
  },
  {
    VarId: "Gender",
    Name: "Gender",
    TypeId: 1,
    general: true,
    CanBeUndefined: false,
  },
  {
    VarId: "BirthDate",
    Name: "Birth date",
    TypeId: 4,
    general: true,
  },
  {
    VarId: "InclusionDate",
    Name: "Inclusion date",
    TypeId: 4,
    IsOptional: false,
    general: true,
  },
  {
    VarId: "LastConsultation",
    Name: "Last follow-up date",
    TypeId: 4,
    general: true,
  },
  {
    VarId: "DeathDate",
    Name: "Death date",
    TypeId: 4,
    general: true,
    CanBeUndefined: true,
  },
  {
    VarId: "EmailId",
    Name: "Email address",
    TypeId: 5,
    general: true,
  },
];
const patient_input_form_vars = {
  FirstName: {
    VarId: "FirstName",
    Name: "First name",
    TypeId: 5,
    IsOptional: false,
    IsSensitive: true,
    general: true,
  },
  LastName: {
    VarId: "LastName",
    Name: "Last name",
    TypeId: 5,
    IsOptional: false,
    IsSensitive: true,
    general: true,
  },
  Initials: {
    VarId: "Initials",
    Name: "Initials",
    TypeId: 5,
    IsOptional: false,
    IsSensitive: true,
    general: true,
  },
  BirthDate: {
    VarId: "BirthDate",
    Name: "Birth date",
    TypeId: 4,
    IsOptional: false,
    IsSensitive: true,
    general: true,
  },
  Gender: {
    VarId: "Gender",
    Name: "Gender",
    TypeId: 1,
    IsOptional: false,
    IsSensitive: false,
    general: true,
    CanBeUndefined: false,
  },
  InclusionDate: {
    VarId: "InclusionDate",
    Name: "Inclusion date",
    TypeId: 4,
    IsOptional: false,
    IsSensitive: false,
    general: true,
  },
  LastConsultation: {
    VarId: "LastConsultation",
    Name: "Last follow-up date",
    TypeId: 4,
    IsOptional: false,
    IsSensitive: false,
    general: true,
  },
  DeathDate: {
    VarId: "DeathDate",
    Name: "Death date",
    TypeId: 4,
    IsOptional: true,
    IsSensitive: false,
    general: true,
    CanBeUndefined: true,
  },
  Email: {
    VarId: "EmailId",
    Name: "Email address",
    TypeId: 5,
    IsSensitive: true,
    general: true,
  },
};

export default { identity_vars, patient_input_form_vars };
