import loginGard from "./login_gard";
import { RouteRecordRaw } from "vue-router";

const organization_routes: Array<RouteRecordRaw> = [
  {
    path: "/organization",
    beforeEnter: loginGard,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/organization/OrganizationHome.vue"),
    children: [
      {
        path: "",
        name: "SeriesList",
        component: () => import("../views/organization/series/OrganizationSeriesList.vue"),
      },
      {
        path: "series/new",
        name: "SeriesNew",
        component: () => import("../views/organization/series/OrganizationSeriesNew.vue"),
      },
      {
        path: "series/import/",
        name: "ImportSeries",
        component: () => import("../views/organization/series/OrganizationSeriesImport.vue"),
      },
    ],
  },
  {
    path: "/organization/:organization_id",
    beforeEnter: loginGard,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/organization/OrganizationHome.vue"),
    children: [
      {
        path: "",
        name: "OrganizationSeriesList",
        component: () => import("../views/organization/series/OrganizationSeriesList.vue"),
      },
      {
        path: "series/new",
        name: "OrganizationSeriesNew",
        component: () => import("../views/organization/series/OrganizationSeriesNew.vue"),
      },
      {
        path: "teams",
        name: "OrganizationTeamsList",
        component: () => import("../views/organization/team/OrganizationTeamsList.vue"),
      },
      {
        path: "teams/new",
        name: "OrganizationTeamNew",
        component: () => import("../views/organization/team/OrganizationTeamNew.vue"),
      },
      {
        path: "teams/:team_id/edit",
        name: "OrganizationTeamEdit",
        component: () => import("../views/organization/team/OrganizationTeamNew.vue"),
      },
      {
        path: "teams/:team_id",
        name: "OrganizationTeamView",
        component: () => import("../views/organization/team/OrganizationTeamView.vue"),
      },
      {
        path: "users",
        name: "OrganizationUsersList",
        component: () => import("../views/organization/user/OrganizationUsersList.vue"),
      },
      {
        path: "users/new",
        name: "OrganizationUserNew",
        component: () => import("../views/organization/user/OrganizationUserNew.vue"),
      },
      {
        path: "users/:user_id/edit",
        name: "OrganizationUserEdit",
        component: () => import("../views/organization/user/OrganizationUserNew.vue"),
      },

      {
        path: "users/:user_id",
        name: "OrganizationUserView",
        component: () => import("../views/organization/user/OrganizationUserView.vue"),
      },
      {
        path: "licenses",
        name: "OrganizationLicenses",
        component: () => import("../views/organization/license/OrganizationLicensesList.vue"),
      },
      {
        path: "charts",
        name: "OrganizationCharts",
        component: () => import("../views/organization/charts/OrganizationChartsList.vue"),
      },
      {
        path: "documents",
        name: "OrganizationDocumentsList",
        component: () => import("../views/organization/documents/OrganizationDocumentsList.vue"),
      },
      {
        path: "documents/new",
        name: "OrganizationDocumentNew",
        component: () => import("../views/organization/documents/OrganizationDocumentNew.vue"),
      },
      {
        path: "documents/:document_id/edit",
        name: "OrganizationDocumentEdit",
        component: () => import("../views/organization/documents/OrganizationDocumentNew.vue"),
      },
      {
        path: "series/import/",
        name: "OrganizationImportSeries",
        component: () => import("../views/organization/series/OrganizationSeriesImport.vue"),
      },
      {
        path: "settings",
        name: "OrganizationSettingsHome",
        component: () => import("../views/organization/settings/OrganizationSettingsHome.vue"),
        children: [
          {
            path: "",
            name: "OrganizationSettingsOrganization",
            component: () => import("../views/organization/settings/OrganizationSettingsOrganization.vue"),
          },
          {
            path: "roles",
            name: "OrganizationSettingsRoles",
            component: () => import("../views/organization/settings/OrganizationSettingsRoles.vue"),
          },
          {
            path: "roles/:role_id",
            name: "OrganizationSettingsRolesEdit",
            component: () => import("../views/organization/settings/OrganizationSettingsRoles.vue"),
          },
          {
            path: "billing_information",
            name: "OrganizationSettingsBilling",
            component: () => import("../views/organization/settings/OrganizationSettingsBilling.vue"),
          },
          // {
          //   path: "communication",
          //   name: "OrganizationSettingsCommunication",
          //   component: () =>
          //     import(
          //       "../views/organization/settings/OrganizationSettingsCommunication.vue"
          //     ),
          // },
        ],
      },
      {
        path: "not_allowed",
        name: "OrganizationNotAllowed",
        component: () => import("../views/organization/NotAllowedOrg.vue"),
      },
    ],
  },
  {
    path: "/users/:user_id/register/:email_hash",
    name: "OrganizationUserSetPassword",
    component: () => import("../views/organization/user/OrganizationUserSetPassword.vue"),
  },
];

export default organization_routes;
