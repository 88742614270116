<template>
  <select @change="change" class="form-control" name="country">
    <option
      v-for="(label, code) in countries"
      :key="code"
      :value="code"
      :selected="code == modelValue"
    >
      {{ label }}
    </option>
  </select>
</template>

<script>
import client from "@/ems/ws_client";

export default {
  props: {
    modelValue: String,
  },
  data: function () {
    return {
      countries: {},
    };
  },
  created: function () {
    client.create(this).getAllDataFromWs("user_country", "GET", {
      all: true,
    });
  },
  methods: {
    change: function (event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
