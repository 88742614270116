window.fwSettings = {
  widget_id: 77000001053,
};
!(function () {
  if ("function" != typeof window.FreshworksWidget) {
    var n = function () {
      n.q.push(arguments);
    };
    (n.q = []), (window.FreshworksWidget = n);
  }
})();
