<template>
  <ImportSerieBox
    :class="'import_box' + (highlight ? ' highlight' : '')"
    @message="$emit('message', $event)"
    :ignore_limit="true"
    @highlight="highlight = $event"
  >
    <label>Drop your file here</label>
    <div class="sublabel">Excel, CSV or ODS file</div>
    <div class="btn btn-primary">Browse my computer</div>
  </ImportSerieBox>
</template>

<script>
import ImportSerieBox from "@/components/ImportSerieBox.vue";

export default {
  props: { intro: String, ignore_limit: Boolean },
  components: { ImportSerieBox },
  data: function () {
    return {
      highlight: false,
    };
  },
};
</script>

<style>
.import_box {
  display: inline-block;
  background-color: rgba(0, 166, 255, 0.14);
  border: 2px dashed #00a6ff;
  border-radius: 10px;
  margin: 0 auto;
}

.import_box #drop-area {
  padding: 3.5em 12em;
}

.import_box label {
  color: #47525e;
  font-size: 1.4em;
  font-weight: bold;
}

.import_box .sublabel {
  color: #969faa;
  font-size: 1.2em;
  font-style: italic;
  margin-bottom: 1em;
}

.import_box.highlight {
  border-color: #00755d;
  background-color: #ddf5ed;
}
</style>
