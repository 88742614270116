<template>
  <div class="loginpage">
    <Login :redir_on_success="redir_on_success" :error="err_code" :initial_messages="messages" :initial_email="email"></Login>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";

export default {
  name: "LoginPage",
  props: {
    error: String,
  },
  data: function () {
    return {
      redir_on_success: "",
      messages: [],
      email: ''
    };
  },
  mounted: function () {
    this.$emit("hide_login_bt", true);

    setTimeout(() => {
      console.log("Login reset", document.location.search);
      const searchParams = new URLSearchParams(document.location.search);
      this.redir_on_success =
        searchParams.get("redir") || this.$route.query.redir;
      if (this.redir_on_success == "reset") {
        console.log("Reset afterlogin in login");
        sessionStorage.removeItem("afterlogin");
      }
    }, 500);
    if(this.$route.query.msg === 'pass_changed') {
        this.messages = [{level: 'success', text: 'Your password has been changed. Please use it to log-in.'}];
      }
      if(this.$route.query.email) {
        this.email = this.$route.query.email;
      }
  },
  computed: {
    err_code: function () {
      return parseInt(this.error || this.$route?.query?.error?.toString());
    },
  },
  components: {
    Login,
  },
};
</script>

<style scoped>
.loginpage {
  position: relative;
  margin: 4.5rem 1rem 1rem 1rem;
  text-align: center;
}
</style>