import { Serie, SerieExtra } from "@/types/serie";

interface OldSerie extends Omit<Serie, "Extra"> {
  Extra: SerieExtra | "";
}

export const blank_series_extra = {
  enabled_feature: {
    serie_patients_sign: 1,
    serie_patients_verify: 1,
  },
  identity_variables: {
    collect_first_name: 1,
    collect_last_name: 1,
    collect_gender: 1,
    collect_death_date: 1,
    collect_birthdate: 1,
    collect_email: 0,
    collect_inclusion_last_follow_up: 1,
    inclusion_description: "",
    last_follow_up_description: "",
  },
};

function set_extra(extra: SerieExtra, current_serie: OldSerie): Serie {
  current_serie.Extra = extra;
  return current_serie as Serie;
}

function handleEmptyExtra(current_serie: OldSerie) {
  return set_extra(blank_series_extra, current_serie);
}

export function handleCurrentSerieExtra(current_serie: OldSerie): Serie {
  if (current_serie.Extra == "") {
    return handleEmptyExtra(current_serie);
  }
  return current_serie as Serie;
}
