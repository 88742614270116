import { defineStore } from "pinia";
import { WSClient } from "@/ems/WSClient";
import { Role } from "@/ems/perms";
import { LoggedUser } from "@/types/user";
import router from "@/router/index";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { computed, ref } from "vue";

export const userSessionStore = defineStore(
  "user_session",
  () => {
    // State
    const logged_user = ref<LoggedUser>({} as LoggedUser);
    const token = ref<string>("");
    const status = ref<"" | "loading" | "error" | "success">("");
    const roles = ref<Role[] | undefined>(undefined);

    // Getters
    const isLoggedIn = computed((): boolean => {
      return !!logged_user.value?.UserId;
    });

    // Actions

    async function session_check(client: WSClient, force_query: boolean): Promise<LoggedUser> {
      if (isLoggedIn.value && !force_query) {
        const current_time = Date.now() / 1000;
        const decodedToken = jwtDecode<JwtPayload>(token.value);
        const token_expiration = decodedToken.exp ? decodedToken.exp : 0;
        if (token.value && token_expiration > current_time) {
          return Promise.resolve(logged_user.value);
        }
      }

      status.value = "loading";
      await client
        .queryWs<{ jwt: string; logged_user: LoggedUser }>("GET", "session_login")
        .then(async (resp) => await on_auth_success(resp.jwt, resp.logged_user))
        .catch(() => {
          on_auth_error();
        });
      return logged_user.value;
    }

    async function register(client: WSClient, login: string, password: string) {
      return new Promise((resolve, reject) => {
        status.value = "loading";
        client
          .queryWs<{ jwt: string; logged_user: LoggedUser }>("POST", "session_register", null, { login, password })
          .then(async (resp) => {
            if (resp && resp.logged_user && resp.logged_user.Email) {
              if (resp.jwt) sessionStorage.setItem("jwt", resp.jwt);
              await on_auth_success(resp.jwt, resp.logged_user);
              resolve(resp);
            }
          })
          .catch((resp) => {
            status.value = "error";
            reject(resp);
          });
      });
    }

    async function logout(client: WSClient) {
      reset_logout();
      client.queryWs("POST", "session_logout");
      router.push({ name: "login" });
    }

    function on_auth_error() {
      status.value = "error";
      reset_logout();
    }

    async function on_auth_success(jwt: string, user: LoggedUser) {
      status.value = "success";
      token.value = jwt;
      logged_user.value = user;
    }

    function reset_logout() {
      status.value = "";
      token.value = "";
      logged_user.value = {} as LoggedUser;
      document.cookie = "PHPSESSID=; Max-Age=0";
    }

    async function get_user_roles(client: WSClient = new WSClient()): Promise<Role[]> {
      if (roles.value === undefined) {
        const resp = await client.queryWs<{ roles: Role[] }>("GET", "user_UserRole");
        roles.value = resp.roles;
      }
      return roles.value;
    }

    return {
      logged_user,
      token,
      status,
      roles,
      isLoggedIn,
      session_check,
      register,
      logout,
      get_user_roles,
    };
  },
  {
    persist: true,
  }
);
