import { store } from "@/store";
import client from "@/ems/ws_client";
import { RouteLocationNormalized } from "vue-router";

const loginGard = async (to: RouteLocationNormalized) => {
  if (to.name == "login" || to.name == "signup" || to.name == "signupInvitedUsers") {
    console.log("No auth required, go to url");
    return true;
  }

  // Auth gard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const user_guard = await normal_user_routing(to);
    if (user_guard) return user_guard;
  }

  // Admin guard
  else if (to.matched.some((record) => record.meta.requiresAdmin)) {
    const admin_guard = await admin_routing(to);
    if (admin_guard) return admin_guard;
  }
  return true;
};

const normal_user_routing = async (to: RouteLocationNormalized) => {
  try {
    let destination = {
      name: to.name,
      params: to.params,
      query: to.query,
    };
    sessionStorage.setItem("afterlogin", JSON.stringify(destination));
  } catch (e) {
    console.log(e);
    console.log("Error sezialisation error");
    console.log(to);
  }
  return await store
    .dispatch("session_check", { force_query: to.name == "root" })
    .then((res: any) => {
      return handle_session_check_res(res, to);
    })
    .catch((err: unknown) => {
      console.error("session_check error", err);
    });
};

const admin_routing = async (to: RouteLocationNormalized) => {
  return await store.dispatch("session_check").then((res: any) => {
    try {
      if (res.logged_user && res.logged_user.UserId) {
        if (res.logged_user.is_admin) {
          return true;
        } else {
          console.log("Not admin redirect to list");
          return { path: "/organization" };
        }
      } else {
        console.log("Not connecto to admin, got to login");
        return {
          name: "login",
          params: { redir: to.fullPath },
        };
      }
    } catch (error) {
      console.error("Error in admin nav gard:");
      console.error(error);
      return false;
    }
  });
};

const gotToRoot = async () => {
  const cl = client.create({}, this);
  return cl
    .query_ws2("user_onboardstatus", "GET")
    .then((res2: any) => {
      return { name: res2.home_name || "series_list" };
    })
    .catch(() => {
      return { name: "series_list" };
    });
};

const check_serie = async (to: RouteLocationNormalized, logged_user: any) => {
  if (!to.params.series_id) {
    return false;
  }

  return await client
    .create({}, this)
    .query_ws2("serie_serie", "GET", {
      serie_id: to.params.series_id,
      with_plan: true,
    })
    .then((res2: any) => {
      return handle_serie_result(res2, to, logged_user);
    });
};

const handle_serie_result = (res2: any, to: RouteLocationNormalized, logged_user: any) => {
  console.log(res2);
  let serie = res2?.serie;
  console.log(serie);
  console.log(logged_user.UserId);

  if (!serie.current_plan?.name && to.name !== "export") {
    console.log("User is unpaid");
    if (serie?.UserId != logged_user.UserId) {
      return {
        name: "CollabRequest",
      };
    }
    return {
      name: "offers",
      query: {
        serie_id: to.params.series_id,
        trial: 1,
      },
    };
  }
  return false;
};

const handle_session_check_res = async (res: any, to: RouteLocationNormalized) => {
  try {
    if (res.logged_user && res.logged_user.UserId) {
      const lu = res.logged_user;
      if ((!lu || lu.payment_status === "unpaying") && !to.matched.some((record) => record.meta.free)) {
        return await check_serie(to, lu);
      }
      if (to.name == "root") {
        console.log("Connected, got to root page");
        const rootRoute = await gotToRoot();
        return rootRoute;
      } else {
        console.log("Connected, go to the url");
        return true;
      }
    } else {
      console.log("Not connected redirect to login");
      return { name: "login", query: { error: 401 } };
    }
  } catch (error) {
    console.error("Error in auth nav gard:");
    console.error(error);
  }
};

export default loginGard;
