import { RouteRecordRaw } from "vue-router";
import loginGard from "./login_gard";

const routes: Array<RouteRecordRaw> = [
    {
        path: "questionnaire/list",
        name: "questionnaire_list",
        beforeEnter: loginGard,
        component: () =>
            import(
         "../views/series/questionnaire/QuestionnaireList.vue"
            ),
    },
    {
        path: "questionnaire/patient_list",
        name: "patient_questionnaire_list",
        beforeEnter: loginGard,
        component: () =>
            import(
         "../views/series/questionnaire/PatientQuestionnaireList.vue"
            ),
    },
    {
        name: "questionnaire_editor",
        path: "questionnaire/:questionnaire_id",
        beforeEnter: loginGard,
        component: () =>
            import(
         "../views/series/questionnaire/QuestionnaireEditor.vue"
            ),
        children: [
            {
                path: "",
                name: "general_info",
                component: () =>
                    import(
             "../views/series/questionnaire/GeneralInfo.vue"
                    ),
            },
            {
                path: "intro",
                name: "intro",
                component: () =>
                    import(
             "../views/series/questionnaire/Intro.vue"
                    ),
            },
            {
                path: "questions",
                name: "questions",
                component: () =>
                    import(
             "../views/series/questionnaire/Questions.vue"
                    ),
            },
            {
                path: "outro",
                name: "outro",
                component: () =>
                    import(
             "../views/series/questionnaire/Outro.vue"
                    ),
            },
            {
                path: "schedule",
                name: "schedule",
                component: () =>
                    import(
             "../views/series/questionnaire/Schedule.vue"
                    ),
            },
            {
                path: "emails",
                name: "emails",
                component: () =>
                    import(
             "../views/series/questionnaire/Emails.vue"
                    ),
            },
            {
                path: "preview",
                name: "preview",
                component: () =>
                    import(
             "../views/series/questionnaire/Preview.vue"
                    ),
            },
        ],
    },
];

export default routes;
