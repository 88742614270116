import { useRouter } from "vue-router";

export function php_app_url(target: string): string {
  if (target.includes("/")) target = target.split("/").slice(-1)[0];
  if (import.meta.env.VITE_PHP_BASE_PATH)
    return import.meta.env.VITE_PHP_BASE_PATH + target;
  const router = useRouter();
  return router.options.history.base.replace("/v", "/") + target;
}

export function goto(url: string): void {
  if (!url) return;
  if (url.includes(".php")) {
    const goUrl = php_app_url(url);
    console.log("Router PHP goto asked " + url + " => " + goUrl);
    window.location.href = goUrl;
  } else {
    console.log("Router direct goto: " + url);
    window.location.href = url;
  }
}
