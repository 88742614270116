<template>
  <div id="onboarding" class="box">
    <h1>
      Welcome to EasyMedStat {{ firstName }}!
      <img src="@/assets/img/party_favors.png" style="vertical-align: -35%; width: 2em" />
    </h1>
    <p class="subtitle">You will now enter the world of easy statistics!</p>
    <div class="two_cols">
      <div>
        <h2 style="font-size: 1.8em; line-height: 1.3em; margin-bottom: 1.5em">
          To start your analysis,<br />
          import a file with your data
        </h2>
        <Messages :messages="messages" />
        <ImportDropBox @message="addMessage($event)"></ImportDropBox>
      </div>
      <div style="margin-top: 2em">
        <h2 style="margin-bottom: 1.5em">I don't have a file with my data</h2>
        <p>
          <router-link class="btn btn-default" to="/organization/series/new"
            >create a new series with our form builder</router-link
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import Messages from "@/components/Messages.vue";
import ImportDropBox from "./ImportDropBox.vue";
import { ApiMessage } from "@/ems/WSClient";
import { useStore } from "vuex";

const store = useStore(),
  messages = ref<ApiMessage[]>([]);

const addMessage = (messages_arr: ApiMessage[]) => {
  messages.value = messages_arr;
};

const firstName = computed(() => {
  return store.getters.logged_user.FirstName || " dear user";
});
</script>

<style scoped>
#onboarding {
  margin-top: 2rem;
  padding: 1em 2em 3em;
  background-repeat: no-repeat;
  background-size: 100%;
}

#onboarding h1 {
  margin-top: 0;
  margin-bottom: 0.3em;
  font-size: 1.87em;
  font-weight: bolder;
}

#onboarding .subtitle {
  margin-top: 0;
  margin-bottom: 3em;
}

#onboarding .two_cols {
  display: flex;
  align-items: flex-end;
}

#onboarding .two_cols > div {
  flex: 1 1 40em;
  padding: 0 5.5em;
  text-align: center;
}

#onboarding h2 {
  margin-left: auto;
  position: relative;
}

#onboarding .two_cols > div:last-child {
  border-left: 1px solid var(--grey-46);
}
</style>
