<template>
  <div>
    <h3>Versions</h3>
    <SmallTopLoader :loading="loading" />
    <Messages :messages="messages" />

    <h3>Other versions</h3>
    <ul>
      <li v-for="version in versions" :key="version.Version">
        {{ version.Version }}
      </li>
    </ul>
  </div>
</template>

<script>
import client from "@/ems/ws_client";
import router from "@/router";
import Messages from "@/components/Messages";
import SmallTopLoader from "@/components/SmallTopLoader";

export default {
  components: { Messages, SmallTopLoader },
  data: function () {
    return {
      loading: false,
      messages: [],
      versions: [],
    };
  },
  mounted: function () {
    this.getVersions().then();
  },
  methods: {
    getVersions: function () {
      return client
        .create(this, router)
        .getAllDataFromWs("env_versionList", "GET");
    },
    getLog: function () {
      client
        .create(this, router)
        .query_ws2("env_log", "GET", {
          logid: this.logid,
          lines: linesNb,
        })
        .then((data) => {
          console.log("Returned log", this.logid);
          this.content = data.replace("\\n", "\n");
        });
    },
  },
  computed: {
    current_version: function () {
      const vs = this.versions.filter((v) => {
        return v.Current == 1;
      });
      return vs.length > 0 ? vs[0] : null;
    },
  },
};
</script>

<style>
.logLnk {
  margin-right: 3em;
}
</style>