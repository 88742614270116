import router from "@/router";
import { WSClient, ApiMessage } from "@/ems/WSClient";
import { RouteLocationNormalized } from "vue-router";

export async function patientGard(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const ws_client = new WSClient();
  try {
    await ws_client.queryWs<{ messages?: ApiMessage[] }>(
      "POST",
      "patient_patientLog",
      {},
      {
        patient_id: to.params.patient_id.toString(),
        serie_id: to.params.series_id.toString(),
        action: "Read",
        text: "",
      }
    );

    console.log("patient resolved");
  } catch {
    if (ws_client.status_code == 401) {
      router.push({ name: "login" });
    } else if (ws_client.status_code == 404) {
      router.push({ name: "NotFound" });
    } else if (ws_client.status_code == 409) {
      if (to.name === "PatientExcludedView") {
        return;
      }
      router.replace({
        name: "PatientExcludedView",
        params: to.params,
      });
    } else {
      router.push({ name: "NotAllowed" });
    }
  }
}
