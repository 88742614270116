<template>
  <div class="password-input">
    <div :class="['input', error ? 'error' : '']">
      <input
        v-if="showpassword"
        type="text"
        :value="password"
        :id="name ? name + 'Clear' : 'PassClear'"
        :name="name ? name + 'Clear' : 'PassClear'"
        :placeholder="placeholder"
        @change.stop="onchange"
        @oninput.stop="oninput"
        @keyup="oninput"
        :required="not_required"
        autocomplete="current-password"
      />
      <input
        v-else
        type="password"
        :value="password"
        :id="name || 'Pass'"
        :name="name || 'Pass'"
        :placeholder="placeholder"
        @change.stop="onchange"
        @oninput.stop="oninput"
        @keyup.stop="oninput"
        :required="!not_required"
        autocomplete="current-password"
      />
      <span @click.stop="showpassword = !showpassword"><font-awesome-icon style="cursor: pointer" icon="eye" /></span>
    </div>
    <div v-show="error" class="msg error">
      {{ strenghtMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { password_strength } from "@/ems/functions";

const props = defineProps<{
  name: string;
  password: string;
  ignore_strength?: boolean;
  placeholder?: string;
  not_required?: boolean;
}>();

const emit = defineEmits<{
  (e: "change", value: string): void;
  (e: "error", value: boolean): void;
}>();

const showpassword = ref(false),
  strength = ref("");

const onchange = ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  const inValue = target.value;
  if (!props.ignore_strength) {
    strength.value = password_strength(inValue);
    if (strength.value !== "strong") {
      console.log("Change ignored because strength " + strength.value);
      return;
    } else {
      console.log("Change accepted because strength is strong");
    }
  }
  console.log("Emit password change");
  emit("change", inValue);
};

const oninput = ($event: Event) => {
  if (props.ignore_strength) {
    onchange($event);
    return;
  }
  if (strength.value || strength.value === "" || strength.value !== "strong") {
    console.log("Input ignored because strength " + strength.value);
    return;
  }
  onchange($event);
};

const strenghtMessage = computed((): string => {
  switch (strength.value) {
    case "weak":
      return "Not strong enough (should be at least 8 characters).";
    case "medium":
      return "Not strong enough (should include lower case, upper case, numbers and special characters).";
    case "strong":
      return "Strong";
    default:
      return "";
  }
});

const error = computed((): boolean => {
  const is_err = strength.value == "weak" || strength.value == "medium";
  emit("error", is_err);
  return is_err;
});
</script>

<style scoped>
.password-input {
  position: relative;
}

.password-input .input {
  display: flex;
  align-items: baseline;
  background-color: rgb(232, 240, 254);
  border-radius: 0.25rem;
  border: 1px solid var(--grey-64);
}

.password-input input {
  background-color: transparent;
  border: 0;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  font-size: 1em;
}

.password-input span {
  padding: 0.36em;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background-color: var(--grey-95);
}

.password-input .msg.error {
  color: var(--red-danger-color);
}

.msg.error {
  margin: 0.8em 0;
}

.error.input {
  border: 2px solid var(--red-danger-color);
}
</style>
