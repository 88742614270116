import client from "@/ems/ws_client";

export const mixpanel_key = import.meta.env.VITE_MIXPANELKEY;

export default {
  // Mixpanel load promise
  initMixpanel: function (mixPanelKey) {
    return new Promise(function (resolve, reject) {
      if (!mixPanelKey) resolve(null);

      if (window.mixpanel) {
        resolve(window.mixpanel);
      } else {
        (function (c, a) {
          if (!a.__SV) {
            var b = window;
            try {
              var d,
                m,
                j,
                k = b.location,
                f = k.hash;
              d = function (a, b) {
                return (m = a.match(RegExp(b + "=([^&]*)"))) ? m[1] : null;
              };
              f &&
                d(f, "state") &&
                ((j = JSON.parse(decodeURIComponent(d(f, "state")))),
                "mpeditor" === j.action &&
                  (b.sessionStorage.setItem("_mpcehash", f),
                  history.replaceState(j.desiredHash || "", c.title, k.pathname + k.search)));
            } catch (n) {}
            var l, h;
            window.mixpanel = a;
            a._i = [];
            a.init = function (b, d, g) {
              function c(b, i) {
                var a = i.split(".");
                2 == a.length && ((b = b[a[0]]), (i = a[1]));
                b[i] = function () {
                  b.push([i].concat(Array.prototype.slice.call(arguments, 0)));
                };
              }
              var e = a;
              "undefined" !== typeof g ? (e = a[g] = []) : (g = "mixpanel");
              e.people = e.people || [];
              e.toString = function (b) {
                var a = "mixpanel";
                "mixpanel" !== g && (a += "." + g);
                b || (a += " (stub)");
                return a;
              };
              e.people.toString = function () {
                return e.toString(1) + ".people (stub)";
              };
              l =
                "disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(
                  " "
                );
              for (h = 0; h < l.length; h++) c(e, l[h]);
              var f = "set set_once union unset remove delete".split(" ");
              e.get_group = function () {
                function a(c) {
                  b[c] = function () {
                    call2_args = arguments;
                    call2 = [c].concat(Array.prototype.slice.call(call2_args, 0));
                    e.push([d, call2]);
                  };
                }
                for (
                  var b = {}, d = ["get_group"].concat(Array.prototype.slice.call(arguments, 0)), c = 0;
                  c < f.length;
                  c++
                )
                  a(f[c]);
                return b;
              };
              a._i.push([b, d, g]);
            };
            a.__SV = 1.2;
            b = c.createElement("script");
            b.type = "text/javascript";
            b.async = !0;
            b.src =
              "undefined" !== typeof MIXPANEL_CUSTOM_LIB_URL
                ? MIXPANEL_CUSTOM_LIB_URL
                : "file:" === c.location.protocol && "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)
                ? "https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js"
                : "//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";

            b.addEventListener("load", function () {
              resolve(window.mixpanel);
            });

            d = c.getElementsByTagName("script")[0];
            d.parentNode.insertBefore(b, d);
          }
        })(document, window.mixpanel || []);
        mixpanel.init(mixPanelKey, {
          api_host: "https://api-eu.mixpanel.com",
          batch_requests: true,
        });
      }
    });
  },

  getMixpanelImpl: async function (client, logged_user) {
    if (mixpanel_key) {
      const mp = await this.initMixpanel(mixpanel_key);
      if (logged_user) mp.identify(logged_user.UserId);
      return mp;
    }
    return this.createNullPanel();
  },

  getMixpanel: function (vueComp, store = null) {
    let logged_used;
    if (store != null) {
      logged_used = store.getters.logged_user;
    } else if (vueComp) {
      logged_used = vueComp.$store.getters.logged_user;
    }
    return this.getMixpanelImpl(client.create(vueComp), vueComp && logged_used);
  },

  getMixpanelPinia: function (vueComp, user_store = null) {
    const logged_user = user_store?.logged_user;
    return this.getMixpanelImpl(client.create(vueComp), vueComp && logged_user);
  },

  mixpanelTrack: function (client, eventName, props) {
    this.getMixpanelImpl(client).then((mp) => {
      mp.track(eventName, props);
    });
  },

  mixpanelUserProperies: function (client, props) {
    this.getMixpanelImpl(client).then((mp) => {
      mp.people.set(props);
    });
  },

  mixpanelIdentify: function (client, userId) {
    this.getMixpanelImpl(client).then((mp) => {
      mp.identify(userId);
    });
  },

  createNullPanel: function () {
    return {
      identify: function () {
        console.log("Mixpanel identify ignored");
      },
      track: function () {
        console.log("Mixpanel track ignored");
      },
      people: {
        set: function () {
          console.log("Mixpanel people set ignored");
        },
      },
    };
  },
};
