<template>
    <button :class="theClass" @click="clicked" :disabled="disabled" :type="type">
        <slot></slot>
        <img alt="Data loading" class="indicator" src="@/assets/img/snake_logo_animation_200.gif" />
    </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

type bType = "button" | "submit" | "reset";
type bStyle = "primary" | "default";

const props = defineProps<{
    btn_style?: bStyle;
    show_indicator: boolean;
    disabled?: boolean;
    type?: bType;
}>();

const emit = defineEmits<{
    (e: "click", val: MouseEvent): void;
}>();

const theClass = computed((): string[] => {
    let cls = ["btn", "with-indicator", "btn-" + (props.btn_style || "default")];
    if (props.show_indicator)
        cls.push("loading");
    return cls;
});

const clicked = ($event: MouseEvent) => {
    emit("click", $event);
};
</script>

<style>
button.with-indicator {
    position: relative;
    transition-timing-function: ease-in;
    padding-right: 0.86em;
}

button.with-indicator.loading {
    padding-right: 2.8em;
    transition: padding-right .3s;
}

button.with-indicator>img.indicator {
    display: none;
}

button.with-indicator.loading>img.indicator {
    display: block;
    position: absolute;
    height: 1.9em;
    width: 1.9em;
    right: 0.7em;
    top: 0.3em;
}
</style>
