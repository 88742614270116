<template>
  <div id="signup_page">
    <SignUp></SignUp>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import SignUp from "@/components/SignUp.vue";

const emit = defineEmits<{
  (e: "hide_login_bt", value: boolean): void;
}>();

onMounted(() => {
  emit("hide_login_bt", true);
});
</script>

<style scoped>
#signup_page {
  position: relative;
  text-align: center;
}
</style>
