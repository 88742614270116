export function round(value, decimals) {
  return new Number(value).toFixed(decimals);
}

export function wupcase(value) {
  if (!value) {
    return "";
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isEmpty(someObject) {
  return !someObject || Object.keys(someObject).length == 0;
}

export function openHelp(articleId) {
  window.FreshworksWidget("open", "article", { id: articleId });
}

export function printPvalue(pvalue, stars) {
  if (pvalue < 0.0001) return "<0.0001" + (stars ? " ****" : "");
  if (pvalue < 0.001) return pvalue.toExponential() + (stars ? " ***" : "");
  if (pvalue < 0.01) return pvalue + (stars ? " **" : "");
  if (pvalue < 0.05) return pvalue + (stars ? " *" : "");
  if (pvalue > 0.999) return ">0.999";
  return pvalue;
}

export function decodeChar(input) {
  if (!input || !input.replace) return input;
  return input.replace(/\\u([0-9a-fA-F]{4})/g, (all, st) => {
    return String.fromCharCode("0x" + st);
  });
}

export function upperCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function scrollTo(element, theYpos) {
  if (!element || !theYpos) return;
  var elemY = element.getBoundingClientRect().y;
  window.scrollBy(0, elemY - theYpos);
}

export function elipsis(input, max) {
  if (!input) return;
  if (input.length < max) return input;
  return input.substring(0, max - 3) + "...";
}

export function php_app_url(target) {
  if (target.includes("/")) target = target.split("/").slice(-1)[0];
  return this.options.history.base.replace("/v", "/") + target;
}

export function password_strength(inValue) {
  const number = /([0-9])/,
    alphabets_lc = /([a-z])/,
    alphabets_uc = /([A-Z])/,
    special_characters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

  if (inValue.length == 0) {
    return "";
  }
  if (inValue.length < 8) {
    return "weak";
  }
  if (
    inValue.match(number) &&
    inValue.match(alphabets_lc) &&
    inValue.match(alphabets_uc) &&
    inValue.match(special_characters)
  ) {
    return "strong";
  }
  return "medium";
}

import * as cnst from "@/ems/const";

export function get_condition_label(condition, variable, value) {
  if (!variable) {
    return "No variable";
  }
  let label_elem_arr = [];
  label_elem_arr.push(variable.Name ?? "unknown");
  label_elem_arr.push(condition.FilterOperand);

  switch (condition.FilterOperand) {
    case cnst.FILTER_OPERATOR_IS_KNOWN:
      label_elem_arr.push("(not missing)");
      break;
    case cnst.FILTER_OPERATOR_IS_UNKNOWN:
      label_elem_arr.push("(missing)");
      break;

    case cnst.FILTER_OPERATOR_EQUALS:
    case cnst.FILTER_OPERATOR_IS:
    case cnst.FILTER_OPERATOR_IS_NOT:
      label_elem_arr.push(getOperand1(condition, variable, value));
      break;

    case cnst.FILTER_OPERATOR_IS_BETWEEN:
    case cnst.FILTER_OPERATOR_IS_NOT_BETWEEN:
    case cnst.FILTER_OPERATOR_IS_BETWEEN_STRICT:
    case cnst.FILTER_OPERATOR_IS_BETWEEN_STRICT_TOP:
    case cnst.FILTER_OPERATOR_IS_BETWEEN_STRICT_BOTTOM:
      label_elem_arr.push(getOperand1(condition, variable, value) + " and " + getOperand2(condition, variable));
      break;

    default:
      console.error("Unknown operand " + condition.FilterOperand + " for displaying filter label");
  }
  return label_elem_arr
    .map((e) => (e ?? "").trim())
    .filter((e) => e !== null && e.trim() !== "")
    .join(" ");
}

function getOperand1(condition, variable, value = null) {
  if (variable.Name === "Gender") {
    switch (condition.FilterValueId) {
      case "W":
        return "Woman";
      case "M":
        return "Man";
      case "0":
        return "Error 0";
      default:
        return "";
    }
  }

  switch (variable.TypeId) {
    case cnst.TYPE_LIST:
    case cnst.TYPE_YESNO:
      return value?.Value ?? "";

    case cnst.TYPE_EVENT:
      if (!condition.FilterDate1 || condition.FilterDate1 == cnst.DEFAULT_DATE_VALUE) return "- ∞";
      return condition.FilterDate1 ?? "";

    case cnst.TYPE_NUMERIC:
      return condition.FilterNumeric1 ? NumericFormat.format(condition.FilterNumeric1, 2) : "- ∞";
    default:
      return "";
  }
}

function getOperand2(condition, variable) {
  switch (variable.TypeId) {
    case cnst.TYPE_EVENT:
      if (!condition.FilterDate2 || condition.FilterDate2 == cnst.DEFAULT_DATE_VALUE) return "+ ∞";
      return condition.FilterDate2 ?? "";

    case cnst.TYPE_NUMERIC:
      return condition.FilterNumeric2 ? NumericFormat.format(condition.FilterNumeric2, 2) : "+ ∞";
    default:
      return "";
  }
}
