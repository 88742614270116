<template>
  <div class="not_found" style="text-align: center; padding: 10rem">
    <div style="top: 20%">
      <p style="font-size: 2rem">Object or page not found.</p>
      <img
        src="@/assets/img/not-found.png"
        alt="Not found"
        style="width: 17rem; margin-top: 3rem"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
