import LoginPage from "@/views/user/LoginPage.vue";
import SignupPage from "@/views/user/SignupPage.vue";
import OnboardingWelcomePage from "@/views/user/OnboardingWelcomePage.vue";
import loginGard from "./login_gard";

const user_routes = [
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    props: true,
  },
  {
    path: "/signup",
    name: "signup",
    component: SignupPage,
  },
  {
    name: "ask_password",
    path: "/ask_password",
    component: () => import("../views/user/AskPassword.vue"),
  },
  {
    name: "password_recovery",
    path: "/password_recovery",
    component: () => import("../views/user/PasswordRecovery.vue"),
  },
  {
    path: "/payment/pro/signup",
    name: "signupInvitedUsers",
    meta: {
      requiresAuth: false,
    },
    component: () => import("../views/payment/pro/SignupInvitedUsers.vue"),
  },
  {
    path: "/onboarding_welcome",
    name: "onboarding_welcome",
    component: OnboardingWelcomePage,
    beforeEnter: loginGard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/paywall/e-crf",
    name: "EcrfPaywall",
    component: () => import("../views/payment/EcrfPaywall.vue"),
  },
  {
    path: "/limit_warning",
    name: "limitWarning",
    component: () => import("../views/series/LimitWarning.vue"),
  },
  {
    path: "/offers",
    name: "offers",
    component: () => import("../views/payment/Offers.vue"),
  },
  {
    path: "/collab_warning",
    name: "CollabWarning",
    component: () => import("../views/payment/CollabWarning.vue"),
  },
  {
    path: "/payment/success/pro",
    name: "ProPostPayment",
    beforeEnter: loginGard,
    meta: {
      requiresAuth: true,
    },
    component: () => import("../views/payment/pro/ProPostPayment.vue"),
  },
  {
    path: "/user_settings",
    component: () => import("../views/user/ProfileHome.vue"),
    beforeEnter: loginGard,
    children: [
      {
        path: "/",
        component: () => import("../views/user/ProfilePersonalInfo.vue"),
      },
      {
        name: "password",
        path: "password",
        component: () => import("../views/user/ProfilePasswordChange.vue"),
      },
      {
        name: "email_prefs",
        path: "email_prefs",
        component: () => import("../views/user/ProfileEmailPreferences.vue"),
      },
      {
        name: "billing",
        path: "billing",
        component: () => import("../views/user/ProfileBilling.vue"),
      },
    ],
    meta: {
      requiresAdmin: true,
    },
  },
];

export default user_routes;
