import client from "@/ems/ws_client";

function setRes(target, res) {
    if (res && res.status == 200)
        for (const [key, value] of Object.entries(res)) {
            target[key.replace(/[^a-zA-Z0-9]/g, "_")] = value;
        }
}

export function getUserPermission(permissions, serie_id = null) {
    if (permissions.join) permissions = permissions.join("|");

    const that = this;
    return client
        .create(that)
        .query_ws2("user_permission", "GET", {
            permission_name: permissions,
            model_id: serie_id,
        })
        .then((resp) => {
            if (that)
                setRes(that, resp);
            return resp;
        });
}


export function getUserRoles() {
    const that = this;
    return client
        .create(that)
        .query_ws2("user_UserRole", "GET")
        .then((resp) => {
            if (that)
                setRes(that, resp);
            return resp;
        });
}

export function getSeriePermission(client, serieid, permissions) {
    console.log("Getting permissions", permissions);
    if (permissions.join) permissions = permissions.join("|");

    return client
        .query_ws2("serie_permission", "GET", {
            sid: serieid,
            permission_name: permissions,
        });
}

export function freshworksEvent(eventName, params) {
    return client.create(this).query_ws2("crm_event", "POST", null, {
        event: eventName,
        params: params,
    });
}
