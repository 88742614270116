import type { Ref } from "vue";
import router from "@/router";

export interface CallForAction {
  action: string;
  text: string;
}
export interface ApiMessage {
  level: string;
  text: string;
  style?: string;
  title?: string;
  call_for_action?: CallForAction;
}

export interface ApiResponse {
  messages?: ApiMessage[];
}

export interface RestResponse {
  status: number;
  json?: ApiResponse;
  textData?: string;
}

export type Methods = "GET" | "POST" | "PUT" | "DELETE";

/**
 * Web-service client
 * @param {*} parent Parent object that will holds results, messages and loading indicator.
 */
export class WSClient {
  static readonly rootApi = import.meta.env.VITE_WS_URL || "";
  loading?: Ref<boolean>;
  messages?: Ref<ApiMessage[]>;
  status_code?: number = -1;
  resetMessages?: boolean = false;
  no_redirect?: boolean;

  constructor(loading?: Ref<boolean>, messages?: Ref<ApiMessage[]>, no_redirect?: boolean, resetMessages = true) {
    this.loading = loading;
    this.messages = messages;
    this.no_redirect = no_redirect;
    this.resetMessages = resetMessages;
  }

  /**
   * Low level query, handle loading and redirection if not auth.
   *
   * @param method
   * @param url
   * @param data
   * @param headers
   * @returns
   */
  async query(
    method: Methods,
    url: string,
    data: BodyInit | FormData | null | undefined,
    headers: HeadersInit | undefined
  ): Promise<Response> {
    this.onLoading(true);

    const httpResponse = await fetch(url, {
      mode: "cors",
      credentials: "include",
      method: method,
      headers: headers,
      body: data,
    });
    this.onLoading(false);
    this.status_code = httpResponse.status;
    if (router) {
      const route = router.currentRoute.value;
      if (route.name != "login") {
        if (httpResponse.status == 401 && !this.no_redirect) {
          router.push({
            name: "login",
            params: { redir_on_success: route.path },
            query: { error: 401 },
          });
        } else if (httpResponse.status == 402 && !this.no_redirect) {
          router.push({
            name: "EcrfPaywall",
            params: { redir_on_success: route.path },
            query: { error: 402 },
          });
        }
      }
    }

    return httpResponse;
  }

  /**
   * Query adapted to generic JSON WS.
   * Handle messages and non JSON responses.
   *
   * @param method
   * @param url
   * @param data
   * @returns
   */
  async queryAllTypes(method: Methods, url: string, data: unknown, queryType: string): Promise<any> {
    let headers = new Headers({ "Content-Type": "application/json" });
    let body_param;
    if (queryType === "form") {
      headers = new Headers({ Accept: "application/json" });
      body_param = data ? (data as BodyInit) : null;
    } else {
      body_param = data ? JSON.stringify(data) : null;
    }
    const httpResponse = await this.query(method, url, body_param, headers);
    const contentType = httpResponse.headers.get("Content-type");
    if (contentType && contentType.startsWith("application/json")) {
      try {
        const json = await httpResponse.json();

        if (!json) {
          return this.handleText(httpResponse.ok, await httpResponse.text());
        }

        this.setMessages(json?.messages);
        if (httpResponse.status > 199 && httpResponse.status < 300) {
          return Promise.resolve(json);
        }
        return Promise.reject(json);
      } catch (err) {
        return this.handleError(err);
      }
    }

    return this.handleText(httpResponse.ok, await httpResponse.text());
  }

  handleText(isOk: boolean, text: string): Promise<any> {
    if (isOk) {
      return Promise.resolve({ text: text });
    }
    this.setMessages([{ level: "error", text: "Error " + text }]);
    return Promise.reject();
  }

  handleError(error: unknown): Promise<ApiResponse> {
    this.onLoading(false);

    if (Array.isArray(error)) {
      this.setMessages(error);
    } else {
      const message = error instanceof Error ? error.message : String(error);
      this.setMessages([{ level: "error", text: "Error " + message }]);
    }

    return new Promise(function (resolve, reject) {
      reject(error);
    });
  }

  setMessages(input: ApiMessage[] | undefined): void {
    if (this.messages) {
      if (this.resetMessages) {
        this.messages.value = [];
      }
      const m_arr = input;
      if (m_arr) {
        this.messages.value.push(...m_arr);
      }
    }
  }

  onLoading(isLoading: boolean): void {
    if (this.loading) {
      this.loading.value = isLoading;
    }
  }

  getWsUrl(
    service: string,
    urlParams: Record<string, string | number | boolean | number[] | string[]> | undefined | null
  ): string {
    return WSClient.rootApi + "/ws2.php?service=" + service + this.toQueryString(urlParams);
  }

  toQueryString(arr: Record<string, string | number | boolean | number[] | string[]> | undefined | null): string {
    if (!arr) return "";
    const params = [] as string[];
    Object.keys(arr)
      .filter((key) => typeof arr[key] != "undefined")
      ?.forEach((key) => {
        const k = `${key}=`,
          v = arr[key];
        if (v === null) return;
        if (Array.isArray(v)) {
          v?.forEach((v2) => {
            params.push(k + encodeURIComponent(v2.toString()));
          });
        } else {
          params.push(k + encodeURIComponent(v.toString()));
        }
      });
    return "&" + params.join("&");
  }

  queryWs<T>(
    method: Methods,
    service: string,
    urlParams?: Record<string, string | number | boolean | number[] | string[]> | null,
    body?: unknown,
    queryType = "json"
  ): Promise<T> {
    return this.queryAllTypes(method, this.getWsUrl(service, urlParams), body, queryType);
  }
}
