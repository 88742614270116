<template>
  <footer>
    <img src="@/assets/img/footer-style-2880w.png" style="width: 100%" /><!--
-->
    <div style="background-color: var(--grey-13); width: 100%">
      <div style="display: flex; margin: 0 auto; width: 80%">
        <div style="flex-grow: 3">
          <img src="@/assets/img/logo-snake-footer.png" style="width: 250px" />
        </div>
        <div style="flex-grow: 1">
          <section>SOLUTIONS</section>
          <p>
            <a class="footer-link" :href="public_site_base_url + '/e-crf'"
              >e-CRF</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              :href="public_site_base_url + '/automated-statistics'"
              >Automated Statistics</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              :href="public_site_base_url + '/collaboration'"
              >Collaboration</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              :href="
                public_site_base_url + '/focus-on-research-not-legal-issues'
              "
              >Focus On Research, Not Legal Issues</a
            >
          </p>
        </div>
        <div style="flex-grow: 1">
          <section>LEGAL</section>
          <p>
            <a
              class="footer-link"
              :href="public_site_base_url + '/terms-of-use'"
              >Terms of use</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              :href="public_site_base_url + '/privacy-policy'"
              >Privacy policy</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              :href="public_site_base_url + '/info/cookies-policy'"
              >Cookie policy</a
            >
          </p>
        </div>
        <div style="flex-grow: 1">
          <section>HELP</section>
          <p>
            <a
              class="footer-link"
              href="http://help.easymedstat.com"
              target="_blank"
              >Help center</a
            >
          </p>
          <p>
            <a
              class="footer-link"
              href="http://help.easymedstat.com/en/support/tickets/new"
              target="_blank"
              >Contact us</a
            >
          </p>
        </div>
      </div>
      <hr style="width: 80%; border-color: #a9a9a9; margin: 2rem auto" />

      <div
        style="
          width: 80%;
          color: rgb(255, 255, 255, 0.8);
          margin: 0 auto;
          padding-bottom: 2rem;
        "
      >
        <span>© 2020 Easy</span><span class="text-green-medium">Med</span
        ><span>Stat. All rights reserved.</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterNav",
  props: {
    public_site_base_url: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
  display: grid;
  grid-column: 1 / 4;
  clear: both;
  content-visibility: auto;
}

footer section {
  font-weight: bold;
  color: white;
}

a.footer-link {
  color: #a1b1bd;
  text-decoration: none;
  cursor: pointer;
}
a.footer-link:hover {
  color: #00e59f;
}
</style>
